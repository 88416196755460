import React from "react"
import styled from "styled-components"

import PurchaseButton from "../buttons/PurchaseButton"
import * as TextStyles from "../styles/TextStyles"

interface Props {
  title: string
  description: string
  buttonTitle?: string
  buttonIcon?: string
  onClick?: () => any
  forPlugin?: boolean
}

const TextSection = (props: Props) => {
  const { title, description, buttonTitle, buttonIcon, onClick, forPlugin = false } = props

  return (
    <Wrapper className="text-wrapper">
      <Title>{title}</Title>
      <Description>{description}</Description>
      {forPlugin ? (
        <LogoContainer>
          <LogoLink
            href="https://www.dropbox.com/sh/5gzfjsbfkpajhmn/AAD7WrPzmBGmPfGie3kFL-64a?dl=0"
            target="_blank"
            color="linear-gradient(16.46deg, #DA732C 31.92%, #F6D24D 88.28%)"
          >
            <Logo src="/images/logos/sketch.png" alt="logo sketch" />
          </LogoLink>
          <LogoLink
            href="https://www.dropbox.com/sh/zun5hdfdso7yr31/AABN10nEpmXbS9ZjWmV-wqqia?dl=0"
            target="_blank"
            color="linear-gradient(338.86deg, #DB5EB8 14.4%, #F8BFEE 85.48%)"
          >
            <Logo src="/images/logos/xd.png" alt="logo xd" />
          </LogoLink>
          <LogoLink
            href="https://www.figma.com/community/plugin/778645840235495725/Angle-Mockups"
            target="_blank"
            color="linear-gradient(192.92deg, #727272 9.97%, #333333 82.89%)"
          >
            <Logo src="/images/logos/figma.png" alt="logo figma" />
          </LogoLink>
        </LogoContainer>
      ) : (
        <>
          <LogoContainer>
            <Logo src="/images/logos/sketch.png" alt="logo sketch" />
            <Logo src="/images/logos/xd.png" alt="logo xd" />
            <Logo src="/images/logos/figma.png" alt="logo figma" />
          </LogoContainer>
          <PurchaseButton title={buttonTitle || ""} icon={buttonIcon || ""} onClick={onClick} />
        </>
      )}
    </Wrapper>
  )
}

export default TextSection

const Wrapper = styled.div`
  display: grid;
  max-width: 372px;
  width: 100%;
  height: fit-content;
  gap: 30px;
  text-align: left;
  padding: 0 20px;

  .purchase {
    margin: 10px 0 0;
  }
`

const Title = styled(TextStyles.H3)`
  max-width: 342px;
`

const Description = styled(TextStyles.MediumText)`
  max-width: 308px;
`

const LogoContainer = styled.div`
  display: grid;
  width: fit-content;
  grid-template-columns: auto auto auto;
  gap: 50px;
`

const Logo = styled.img`
  width: auto;
  height: auto;
  max-height: 32px;
  margin: auto;
`

const LogoLink = styled.a`
  display: grid;
  width: 60px;
  height: 60px;
  background: ${props =>
    props.color || "linear-gradient(16.46deg, #DA732C 31.92%, #F6D24D 88.28%)"};
  border-radius: 50%;
  box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.5), inset 1px 1px 2px 1px rgba(0, 0, 0, 0.15);

  &,
  * {
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover {
    transform: scale(1.1) translateY(-2px);
  }
`
