import React from "react"
import styled, { keyframes } from "styled-components"
import { graphql } from "gatsby"

import Layout from "../components/layout/Layout"
import { MetadataReadonly } from "../types/contentfulTypes"
import TutorialCard from "../components/cards/TutorialCard"
import * as TextStyles from "../components/styles/TextStyles"
import { StaticImage } from "gatsby-plugin-image"
import TutorialsPluginSection from "../components/sections/TutorialsPluginSection"
import PillButton from "../components/buttons/PillButton"

interface Props {
  data: {
    contentfulMetadata: MetadataReadonly
    allContentfulTutorial: any
  }
}

const Tutorials = (props: Props) => {
  const { data } = props

  const allVideos = data.allContentfulTutorial.edges.flatMap((video: any) => {
    return video?.node
  })

  return (
    <Layout {...data}>
      <Wave />
      <Wrapper>
        <Container>
          <StaticImage
            className="illustration"
            src="../images/illustrations/illustration7.webp"
            alt="illustration"
          />
          <TextContainer>
            <Title>Video Tutorials</Title>
            <Description>
              Videos showcasing how to use Angle so that you can make the best use of our library.
            </Description>
          </TextContainer>
        </Container>
        <CardContainer>
          {allVideos.map((video, index) => (
            <TutorialCard key={index} {...video} />
          ))}
        </CardContainer>
        <TutorialsPluginSection />
        <SupportContainer>
          <Title>Need more help?</Title>
          <Description>We are always here help to you. Feel free to contact us.</Description>
          <a href="mailto:angle@designcode.io" target="_blank" rel="noopener noreferrer">
            <PillButton icon="/images/icons/mail-purple.svg" title="Send us a email" />
          </a>
        </SupportContainer>
      </Wrapper>
    </Layout>
  )
}

export default Tutorials

export const query = graphql`
  query TutorialsQuery {
    contentfulMetadata(slug: { eq: "tutorials" }) {
      ...MetadataFragment
    }
    allContentfulTutorial(sort: { fields: createdAt, order: ASC }) {
      edges {
        node {
          title
          link
          description {
            description
          }
          background {
            file {
              url
            }
          }
          createdAt
        }
      }
    }
  }
`

const fadeIn = keyframes`
from { opacity: 0; }
to { opacity: 1; }
`

const Wrapper = styled.div`
  width: 100%;
  padding: 200px 20px;
`

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 340px;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    text-align: center;
    grid-template-columns: 1fr;
    padding-bottom: 40px;
  }

  .illustration {
    max-width: 700px;
    opacity: 0;
    animation: ${fadeIn} 2s 1s forwards;
  }
`

const TextContainer = styled.div`
  display: grid;
  max-width: 340px;
  height: fit-content;
  margin: 0 auto;
  gap: 30px;
`

const Title = styled(TextStyles.H2)``

const Description = styled(TextStyles.MediumText)``

const CardContainer = styled.div`
  max-width: 1234px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(584px, 1fr));
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin: 0 auto;

  @media (max-width: 624px) {
    grid-template-columns: 1fr;
  }
`

const SupportContainer = styled.div`
  display: grid;
  gap: 20px;
  text-align: center;
  justify-content: center;

  a {
    text-decoration: none;
  }

  p {
    max-width: 320px;
    margin: 0 auto;
  }
`

const Wave = styled.div`
  position: absolute;
  width: 100%;
  height: 1110px;
  top: 100px;
  left: -400px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 1110px;
  background-image: url("/images/backgrounds/tutorials-wave.webp");
  opacity: 0;
  animation: ${fadeIn} 1.5s forwards;

  @media (max-width: 1000px) {
    left: 0px;
    background-size: 1440px;
  }
`
