import React from "react"
import styled from "styled-components"

import * as TextStyles from "../styles/TextStyles"
import PlayButton from "../buttons/PlayButton"

interface Props {
  title: string
  link: string
  description: {
    description: string
  }
  background: {
    file: {
      url: string
    }
  }
  createdAt: string
}

const TutorialCard = (props: Props) => {
  const { title, link, description, background } = props

  return (
    <Wrapper>
      <Background src={background.file.url} />
      <ButtonContainer>
        <PlayButton link={link} />
      </ButtonContainer>
      <TextContainer>
        <Title>{title}</Title>
        <Caption>{description.description}</Caption>
      </TextContainer>
    </Wrapper>
  )
}

export default TutorialCard

const Wrapper = styled.div`
  position: relative;
  display: grid;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 0 20px 40px;
  background: #fafcff;
  box-shadow: 0px 40px 80px rgba(0, 0, 0, 0.15);
  border-radius: 35px;
  margin: 0 auto;

  &,
  * {
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover {
    transform: translateY(-6px) scale(1.02);
  }
`

const Background = styled.img`
  max-width: 544px;
  width: 100%;
  margin: 0 auto;
`

const TextContainer = styled.div`
  display: grid;
  max-width: 500px;
  margin: 0 auto;
  text-align: left;
  gap: 30px;
  padding-top: 40px;
`

const Title = styled(TextStyles.H4)`
  color: #000000;
`

const Caption = styled(TextStyles.MediumText)`
  color: #000000;
`

const ButtonContainer = styled.div`
  position: absolute;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
