import React from "react"
import styled from "styled-components"

interface Props {
  link: string
}

const PlayButton = (props: Props) => {
  const { link } = props

  return (
    <a href={link} rel="noopener noreferrer" target="_blank">
      <PlayButtonWrapper>
        <img src="/images/icons/play-icon.svg" alt="play button" />
      </PlayButtonWrapper>
    </a>
  )
}

export default PlayButton

const PlayButtonWrapper = styled.div`
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.6) 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 10px 20px rgba(69, 42, 124, 0.15);
  display: grid;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    margin: 0;
    margin-left: 6px;
  }

  &,
  * {
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover {
    transform: scale(1.1);
    transform: translateY(2px);
  }
`
