import React from "react"
import styled from "styled-components"

import * as TextStyles from "../styles/TextStyles"
import { themes } from "../styles/ColorStyles"

interface Props {
  icon?: string
  title: string
  caption?: string
  color?: string
  onClick?: () => any
}

interface StyledProps {
  color?: string
}

const PurchaseButton = (props: Props) => {
  const { icon, title, caption, color, onClick } = props

  return (
    <Wrapper className="purchase" onClick={onClick}>
      <Circle className="circle" color={color} />
      <Container>
        <Icon className="icon" src={icon || "/images/icons/credit-pink.svg"} alt="icon" />
      </Container>
      <TextContainer>
        <Title>{title}</Title>
        <Caption>{caption}</Caption>
      </TextContainer>
    </Wrapper>
  )
}

export default PurchaseButton

const Wrapper = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  width: fit-content;
  height: fit-content;
  background: linear-gradient(180deg, #ffffff 0%, #e6ecf0 100%);
  box-shadow: 0px 3px 5px rgba(38, 62, 84, 0.1), 0px 10px 20px rgba(38, 62, 84, 0.1),
    inset 0px -1px 0px rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  cursor: pointer;

  &,
  * {
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover {
    transform: translateY(-2px);

    .icon {
      transform: scale(1.1);
    }
  }
`

const Container = styled.div`
  display: grid;
  justify-content: center;
  position: relative;
  width: 71px;
  background: ${themes.light.pricingCard.backgroundColor};
  backdrop-filter: blur(20px);
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  z-index: 2;
`

const Icon = styled.img`
  width: fit-content;
  height: fit-content;
  margin: auto;
`

const Circle = styled.div<StyledProps>`
  position: absolute;
  width: 62px;
  height: 62px;
  top: -15px;
  background: ${props =>
    props.color || "linear-gradient(338.47deg, #db5eb8 15.24%, #f8bfee 82.47%)"};
  border-radius: 50%;
  box-sizing: border-box;
  backdrop-filter: blur(15px);
  z-index: 1;
`

const TextContainer = styled.div`
  padding: 12px 40px 12px 12px;
  text-align: left;
`

const Title = styled(TextStyles.MediumText)`
  color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
  font-weight: 500;
`

const Caption = styled(TextStyles.Caption)`
  color: #6c6a8e;
  font-weight: 600;
  text-transform: uppercase;
`
