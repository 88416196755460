import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import TextSection from "./TextSection"

const TutorialsPluginSection = () => {
  return (
    <Wrapper>
      <Container>
        <TextSection
          title="Install the Angle Plugin"
          description="We've created a free Plugin that allows you to place your screen into perspective mockups. Download 50 free mockups and try the plugin."
          forPlugin={true}
        />
        <StaticImage
          className="phones"
          src="../../images/illustrations/illustration8.webp"
          alt="phones"
        />
      </Container>
    </Wrapper>
  )
}

export default TutorialsPluginSection

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  padding: 0 20px;
  margin-bottom: 200px;

  .phones {
    max-width: 694px;
  }
`

const Container = styled.div`
  display: grid;
  max-width: 1234px;
  margin: 0 auto;
  grid-template-columns: auto auto;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  padding-top: 180px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: center;
    gap: 50px;
  }
`
